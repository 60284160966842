import React from "react";
import { useState, useEffect } from "react";
import { Box, Paper, useTheme } from '@mui/material';
import { tokens } from "../../../assets/theme";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import AxionControls from "../../../components/controls/AxionControls";
import { fetchPlcLogs } from "../../../fetchers/plclog";
import { useQuery } from "@tanstack/react-query";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import useFeatureFlags, { isFeatureEnabled }  from "../../../hooks/useFeatureFlags";

export default function PlcLogDialog(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const apiRef = useGridApiRef();
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [sortModelStorage, setSortModelStorage] = useState([
        { field: "DateAndTime", sort: "desc" }
      ]);
    const [machine, setMachine] = useState(props.machineName);

    const featuresQuery = useFeatureFlags();
    
    const plclogResult = useQuery({
        queryKey: ['plclog', machine], 
        queryFn: () => fetchPlcLogs(machine),
        refetchInterval: 60000,
        staleTime: 60000,
    });

    useEffect(() => {
      if(isFeatureEnabled(featuresQuery, "UseMachineIdForPlcLoggQueries")) {
        setMachine(props.machineId);
      }
    }, [featuresQuery.data, props.machineId]);


    let columns = [
      { 
          field: "dateAndTime", 
          headerName: <FormattedMessage {...messages.dateAndTimeColumn} />,
          flex: 1,
          valueFormatter: (value) => {
            if (value == null) {
              return '';
            }
            return new Date(value.value).toLocaleString('sv-SE');
          },
      },
      { 
          field: "machine", 
          headerName: <FormattedMessage {...messages.machineNameColumn} />,
          flex: 1,
      },
      { 
          field: "counter", 
          headerName: <FormattedMessage {...messages.counterColumn} />,
          flex: 1,
      },
      { 
          field: "actCycleTime", 
          headerName: <FormattedMessage {...messages.cycleTimeColumn} />,
          flex: 1,
      },
      { 
          field: "machineStatus", 
          headerName: <FormattedMessage {...messages.machineStatusColumn} />,
          flex: 1,
      },
    ];

    const columnOrderLocalStorageName = 'plclog-columnOrder';
    const columnWidthsLocalStorageName = 'plclog-columnWidths';
    
    // Load persisted column order, widths, and sort order on mount
    function initColumnsFromLocalStorage(columns) {
      let columnsToFix = columns;
      // Load column order
      const savedColumnOrder = localStorage.getItem(columnOrderLocalStorageName);
      if (savedColumnOrder) {
        const orderArray = JSON.parse(savedColumnOrder);
        columnsToFix = reorderColumns(orderArray, columnsToFix);
      }

      // Load column widths
      const savedWidths = localStorage.getItem(columnWidthsLocalStorageName);
      if (savedWidths) {
          const widthMap = JSON.parse(savedWidths);
          columnsToFix = applyColumnWidths(widthMap, columnsToFix);
        }
      return columnsToFix;
    };


    // Reorder columns based on saved order
    const reorderColumns = (orderArray, columnsToFix) => {
      const reorderedColumns = orderArray.map((field) =>
          columnsToFix.find((col) => col.field === field)
      );
      reorderedColumns.push(...columnsToFix.filter((col) => !reorderedColumns.includes(col)));
      return reorderedColumns;
    };

    // Apply saved column widths
    const applyColumnWidths = (widthMap, columnsToFix) => {
      const updatedColumns = columnsToFix.map((col) => ({
        ...col,
        width: widthMap[col.field] || col.width, 
        flex: widthMap[col.field] !== undefined ? undefined : col.flex, 
      }));
      return updatedColumns;
    };

    // Handle column order change
    const handleColumnOrderChange = () => {
      if (!apiRef.current) {
        return;
      }
      const orderedColumns = apiRef.current.getVisibleColumns();
      const newColumnOrder = orderedColumns.map((col) => col.field);
      localStorage.setItem(columnOrderLocalStorageName, JSON.stringify(newColumnOrder));
    };

    // Handle column resize
    const handleColumnResize = (params) => {
      const { colDef, width } = params;
      const newWidths = JSON.parse(localStorage.getItem(columnWidthsLocalStorageName)) || {};
      newWidths[colDef.field] = width;
      localStorage.setItem(columnWidthsLocalStorageName, JSON.stringify(newWidths));
    };

    if(plclogResult?.isLoading || featuresQuery.isLoading) {
        return <AxionControls.AxionBackdrop open={true} />
    }

    columns = initColumnsFromLocalStorage(columns);

    return (
        <Box>
          <Paper
            sx={{
              position: "relative",
              borderRadius: "4px",
            }}
          >
            <Box 
              m="0px 0 0 0"
              height="76vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                },
                "& .name.column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-cellContent": {
                  fontSize: "13px",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.primary[600],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.primary[600],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.gray[100]} !important`,
                },
                "& .super-app-theme--11": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .super-app-theme--undefined-stop": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .MuiDataGrid-columnHeader": {
                  fontSize: "15px",
                },
              }}
            >
              { (!plclogResult?.isLoading) && <DataGridPro
                apiRef={apiRef}
                onColumnResize={handleColumnResize}
                onColumnOrderChange={handleColumnOrderChange}
                rows={plclogResult?.data}
                sortModel={sortModelStorage}
                onSortModelChange={(newModel) => setSortModelStorage(newModel)}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              /> }
            </Box>
          </Paper>
        </Box>
    );
};