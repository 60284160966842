import React, { Component, useState, useEffect } from "react";
import { ColorModeContext, useMode } from "./assets/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Topbar from "./layouts/Topbar";
import AppRoutes from "./AppRoutes";
import Dashboard from "./scenes/oeeDashboard";
import Sidebar from "./layouts/Sidebar";
import axios from "axios";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsalAuthentication,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import {
  InteractionType,
  PublicClientApplication,
  EventType,
} from "@azure/msal-browser";
import RequestInterceptor from "./auth/RequestInterceptor";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LicenseInfo } from "@mui/x-license-pro";
import saveUserToDatabase from "./components/User/saveUserToDatabase";
import { IntlProvider } from 'react-intl';
import { useSelector } from "react-redux";
import MonitorIntegrationEnabledChecker from "./features/monitorIntegration/components/monitorIntegrationEnabled";
import { ToastContainer } from "react-toastify";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
//SignalR


// import { translationMessages } from './i18n';
//import { Layout } from './components/Layout';
//import './custom.css';
LicenseInfo.setLicenseKey(
  "e3bcf6b64867053177be369d1df903edTz04MzMxMixFPTE3MzgzMjc3MjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
const queryClient = new QueryClient({});
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [userRoles, setUserRoles] = useState([]);
  useMsalAuthentication(InteractionType.Redirect);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const defaultLocale = useSelector((state) => state.language.language);

  const messages = require(`./translations/${defaultLocale}.json`) // en.json 
  const locale = defaultLocale

  // const browserHistory = createBrowserHistory({ basename: '' });
  // var reactPlugin = new ReactPlugin();

  // Disabled telemetry for now // Henrik 2024-10-21
  // var appInsights = new ApplicationInsights({
  //   config: {
  //       //Axjo      
  //       connectionString: 'InstrumentationKey=fd679384-0118-449e-ae4e-e9a6ea4e0387;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/;ApplicationId=4607efe0-2998-4f92-a40e-6ce81d995b51',
        
  //       // Axion
  //       //connectionString: 'InstrumentationKey=7aa4125d-9755-4962-baa1-1ffa71f88d2b;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/;ApplicationId=c59e2c05-6917-40f9-8aa9-893a62c9b6c4',
  //       enableAutoRouteTracking: true,
  //       extensions: [reactPlugin],
  //       extensionConfig: {
  //         [reactPlugin.identifier]: { history: browserHistory }
  //       }
  //   }
  // });
  // appInsights.loadAppInsights();
  // useEffect(() => {
  //   axios.get("/api/user").then((response) => {
  //     setUserRoles(response.data);
  //   }).catch((error) => console.log(error));
  // }, []);

  // const msalInstance = usems
  instance.addEventCallback((message) => {
    // Update UI or interact with EventMessage here
    if (message.eventType === EventType.LOGIN_SUCCESS) {
      console.log(message.payload);
    }
  });
  // saveUserToDatabase(account);

  return (
    <IntlProvider
    locale={locale}
    messages={messages}
>
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthenticatedTemplate>
          <QueryClientProvider client={queryClient}>
            <RequestInterceptor>
              <div className="app">
                <Sidebar isSidebar={isSidebar} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <ToastContainer />
                <main className="content">
                  <Topbar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />

                  <Routes>
                    {AppRoutes.map((route, index) => {
                      const { element, ...rest } = route;
                      return <Route key={index} {...rest} element={element} />;
                    })}
                    
                  </Routes>
                </main>
              </div>
              <ReactQueryDevtools initialIsOpen={false} />
            </RequestInterceptor>
          </QueryClientProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <p> You are not signed in! Please sign in.</p>
        </UnauthenticatedTemplate>
      </ThemeProvider>
    </ColorModeContext.Provider>
    </IntlProvider>
  );
}

export default App;
