import { Box, Typography, useTheme, IconButton, Stack } from "@mui/material";
import { tokens } from "../../../assets/theme";
import React, { useContext, useState, useEffect, Components } from 'react'
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import PercentageBar from "./PercentageBar";
import { useQuery } from "@tanstack/react-query";
import { fetchMachineDetails, fetchOeeGridDateIntervalmachine } from "../../../fetchers/machine";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { useMsal, useAccount } from "@azure/msal-react";
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const Infoboxes = (props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { accounts } = useMsal();
    const { formatMessage } = useIntl();
    const account = useAccount(accounts[0]);
    const machineDetailsQuery = useQuery(
        ["machineDetails", props.companyKey, props.timeSpan, props.machineName],
        () => fetchMachineDetails(props.companyKey, props.timeSpan, props.machineName),
        {
            staleTime: 60000,
            refetchInterval: 60000,
            enabled: !props.useDateInterval,
            // keepPreviousData: true
        }
    );

    const machineDetailsDateIntervalQuery = useQuery(
        ["machineDetailsDateInterval", props.startDate, props.endDate, props.companyKey, props.machineId],
        () => fetchOeeGridDateIntervalmachine(props.startDate, props.endDate, props.companyKey, props.machineId),
        {
            staleTime: 60000,
            refetchInterval: 60000,
            enabled: props.useDateInterval,
            // keepPreviousData: true  
        }
    );

    if (props.machineInfo == null) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="73vh">
                <Typography variant="h5" color="error">machineInfo or machineDetails are missing for current time range</Typography>
            </Box>
        )
    }

    //MachineInfo
    let machineStatus = ""
    let currentStop = "";
    let percentageDone = "";
    let percentageDoneMax = 0;
    let runningMachine = "";
    let OEEPercentBad = "";
    let OEEPercentWarning = "";
    let grafanaLink = "";
    let machineInfo = {};

    if (props.machineInfo !== undefined && props.machineInfo !== null && !isNaN(props.machineInfo.percentageDone)) {
        machineInfo = props.machineInfo;
        //machineDetails = props.useDateInterval === false ? machineDetailsQuery.data[0] : machineDetailsDateIntervalQuery.data[0];
        currentStop = `(${machineInfo.currentStop})`;
        machineStatus = machineInfo.machineStatus;
        percentageDone = Math.round(machineInfo.percentageDone * 100);
        percentageDoneMax = Math.round(machineInfo.percentageDone * 100);
        if (percentageDoneMax > 100) { percentageDoneMax = 100 };
        runningMachine = machineInfo.machineStatus === "Running";
        OEEPercentBad = machineInfo.oeePercentBad;
        OEEPercentWarning = machineInfo.oeePercentWarning;
        grafanaLink = props.company?.grafanaBaseUrl?.replace("{machineName}", props.machineName);
    }

    //MachineDetails
    let t = "";
    let a = "";
    let k = "";
    let tak = "";
    let numberOfCyclesWithCavity = "";
    let lastCycleTime = "";
    let idealCycleTime = "";
    let scrappedQty = "";
    let remainingTimeRaw = "";
    let uptime = "";
    let downTime = "";
    let machineDetails = {};
    if ((props.useDateInterval === false && machineDetailsQuery.data !== undefined && machineDetailsQuery.data.length > 0) || (props.useDateInterval === true && machineDetailsDateIntervalQuery.data !== undefined && machineDetailsDateIntervalQuery.data.length > 0)) {

        machineDetails = props.useDateInterval === false ?
            machineDetailsQuery.data[0] :
            machineDetailsDateIntervalQuery.data[0];

        lastCycleTime = machineDetails.lastCycleTime;
        idealCycleTime = machineDetails.idealCycleTimeActive;
        // Calculate remaining time
        remainingTimeRaw = machineDetails.remainingTime;
        // Calculate OEE
        t = machineDetails.t;
        a = machineDetails.a;
        k = machineDetails.k;
        let aCalc = a;
        if (props.machine?.meassurePerformance === false) {
            a = 100;
        }
        if (props.machine?.meassureQuality === false) {
            k = 100;
        }
        if (aCalc > 100) {
            aCalc = 100;
        }
        tak = (t / 100) * (aCalc / 100) * (k / 100) * 100;
        numberOfCyclesWithCavity = machineDetails.numberOfCyclesWithCavity;
        scrappedQty = machineDetails.scrappedQty;
        uptime = machineDetails.uptime;
        downTime = machineDetails.downTime;
    }

    // Get time in correct unit
    function getTimeRecalc(minutes) {

        if (minutes === undefined || minutes === null || minutes === "") {
            return "";
        }

        var time = minutes.toFixed(2); // minutes
        if (minutes > 60) {
            time = (minutes / 60).toFixed(2); // Hours
        }
        if (minutes > 1440) {
            time = (minutes / 1440).toFixed(2); // Days
        }
        return time;
    }
    // Get correct time unit
    function getTimeUnit(minutes) {
        if (minutes === undefined || minutes === null || minutes === "") {
            return "";
        }
        var unit = "min";
        if (minutes > 60) {
            unit = "h";
        }
        if (minutes > 1440) {
            unit = "days";
        }
        return unit;
    }

    function roundOrEmptystring(value, attributeName) {
        if (value === undefined || value === null || value === "") {
            return "";
        }
        if (attributeName === "t" && props.machine?.meassureAvailability === false) {
            return " - ";
        }
        else if (attributeName === "a" && props.machine?.meassurePerformance === false) {
            return " - ";
        }
        else if (attributeName === "k" && props.machine?.meassureQuality === false) {
            return " - ";
        }
        return Math.round(value);
    }
    function showStringIfNotEmptyValue(value, string, attributeName) {
        if (value === undefined || value === null || value === "") {
            return "";
        }
        else if (attributeName === "t" && props.machine?.meassureAvailability === false) {
            return "";
        }
        else if (attributeName === "a" && props.machine?.meassurePerformance === false) {
            return "";
        }
        else if (attributeName === "k" && props.machine?.meassureQuality === false) {
            return "";
        }
        return string;
    }

    function cycleTimeColor(lastCT, idealCT) {
        if (lastCT === 0 || lastCT === "") {
            return `${colors.primary[400]}, ${colors.primary[500]}`;
        }

        var yellowExpandPercent = 0.05;
        var redExpandPercent = 0.1;
        var result = `${colors.dashbordGreen[600]}, ${colors.dashbordGreen[800]}`;
        if (lastCT > (idealCT + idealCT * redExpandPercent)) {
            result = `${colors.redAccent[500]}, ${colors.redAccent[700]}`;
        }
        else if (lastCT > (idealCT + idealCT * yellowExpandPercent)) {
            result = `${colors.yellow[500]}, ${colors.yellow[700]}`;
        }

        return result;
    }

    function getAvalabilityThresholds() {
        let percentBad = 65;
        let percentWarning = 78;

        if (props.machine?.availabilityPercentBad !== undefined && props.machine?.availabilityPercentBad !== null) {
            percentBad = props.machine.availabilityPercentBad;
        }
        else if (props.company?.availabilityPercentBad !== undefined && props.company?.availabilityPercentBad !== null) {
            percentBad = props.company.availabilityPercentBad;
        }
        else if (props.machine?.oeePercentBad !== undefined && props.machine?.oeePercentBad !== null) {
            percentBad = props.machine.oeePercentBad;
        }
        else if (props.company?.oeePercentBad !== undefined && props.company?.oeePercentBad !== null) {
            percentBad = props.company.oeePercentBad;
        }

        if (props.machine?.availabilityPercentWarning !== undefined && props.machine?.availabilityPercentWarning !== null) {
            percentWarning = props.machine.availabilityPercentWarning;
        }
        else if (props.company?.availabilityPercentBad !== undefined && props.company?.availabilityPercentBad !== null) {
            percentWarning = props.company.availabilityPercentWarning;
        }
        else if (props.machine?.oeePercentBad !== undefined && props.machine?.oeePercentWarning !== null) {
            percentWarning = props.machine.oeePercentWarning;
        }
        else if (props.company?.oeePercentBad !== undefined && props.company?.oeePercentBad !== null) {
            percentWarning = props.company.oeePercentWarning;
        }

        return { percentBad: percentBad, percentWarning: percentWarning };
    }

    function getPerformanceThresholds() {
        let percentBad = 65;
        let percentWarning = 78;

        if (props.machine?.performancePercentBad !== undefined && props.machine?.performancePercentBad !== null) {
            percentBad = props.machine.performancePercentBad;
        }
        else if (props.company?.performancePercentBad !== undefined && props.company?.performancePercentBad !== null) {
            percentBad = props.company.performancePercentBad;
        }
        else if (props.machine?.oeePercentBad !== undefined && props.machine?.oeePercentBad !== null) {
            percentBad = props.machine.oeePercentBad;
        }
        else if (props.company?.oeePercentBad !== undefined && props.company?.oeePercentBad !== null) {
            percentBad = props.company.oeePercentBad;
        }

        if (props.machine?.performancePercentWarning !== undefined && props.machine?.performancePercentWarning !== null) {
            percentWarning = props.machine.performancePercentWarning;
        }
        else if (props.company?.performancePercentWarning !== undefined && props.company?.performancePercentWarning !== null) {
            percentWarning = props.company.performancePercentWarning;
        }
        else if (props.machine?.oeePercentBad !== undefined && props.machine?.oeePercentWarning !== null) {
            percentWarning = props.machine.oeePercentWarning;
        }
        else if (props.company?.oeePercentBad !== undefined && props.company?.oeePercentBad !== null) {
            percentWarning = props.company.oeePercentWarning;
        }

        return { percentBad: percentBad, percentWarning: percentWarning };
    }

    function getQualityThresholds() {
        let percentBad = 65;
        let percentWarning = 78;

        if (props.machine?.qualityPercentBad !== undefined && props.machine?.qualityPercentBad !== null) {
            percentBad = props.machine.qualityPercentBad;
        }
        else if (props.company?.qualityPercentBad !== undefined && props.company?.qualityPercentBad !== null) {
            percentBad = props.company.performancePercentBad;
        }
        else if (props.machine?.oeePercentBad !== undefined && props.machine?.oeePercentBad !== null) {
            percentBad = props.machine.oeePercentBad;
        }
        else if (props.company?.oeePercentBad !== undefined && props.company?.oeePercentBad !== null) {
            percentBad = props.company.oeePercentBad;
        }

        if (props.machine?.qualityPercentWarning !== undefined && props.machine?.qualityPercentWarning !== null) {
            percentWarning = props.machine.qualityPercentWarning;
        }
        else if (props.company?.qualityPercentWarning !== undefined && props.company?.qualityPercentWarning !== null) {
            percentWarning = props.company.qualityPercentWarning;
        }
        else if (props.machine?.oeePercentBad !== undefined && props.machine?.oeePercentWarning !== null) {
            percentWarning = props.machine.oeePercentWarning;
        }
        else if (props.company?.oeePercentBad !== undefined && props.company?.oeePercentBad !== null) {
            percentWarning = props.company.oeePercentWarning;
        }

        return { percentBad: percentBad, percentWarning: percentWarning };
    }

    function getOeeThresholds() {
        let percentBad = 65;
        let percentWarning = 78;

        if (props.machine?.oeePercentBad !== undefined && props.machine?.oeePercentBad !== null) {
            percentBad = props.machine.oeePercentBad;
        }
        else if (props.company?.oeePercentBad !== undefined && props.company?.oeePercentBad !== null) {
            percentBad = props.company.oeePercentBad;
        }

        if (props.machine?.oeePercentBad !== undefined && props.machine?.oeePercentWarning !== null) {
            percentWarning = props.machine.oeePercentWarning;
        }
        else if (props.company?.oeePercentBad !== undefined && props.company?.oeePercentBad !== null) {
            percentWarning = props.company.oeePercentWarning;
        }

        return { percentBad: percentBad, percentWarning: percentWarning };
    }

    function getBoxColor(value, attributeName) {
        if (value === "") {
            return `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})`;
        }

        let percentBad = 65;
        let percentWarning = 78;
        if (attributeName === "t") {
            ({ percentBad = percentBad, percentWarning = percentWarning } = getAvalabilityThresholds());
        }

        else if (attributeName === "a") {
            ({ percentBad = percentBad, percentWarning = percentWarning } = getPerformanceThresholds());
        }

        else if (attributeName === "k") {
            ({ percentBad = percentBad, percentWarning = percentWarning } = getQualityThresholds());
        }

        else if (attributeName === "tak") {
            ({ percentBad = percentBad, percentWarning = percentWarning } = getOeeThresholds());
        }

        if (attributeName === "a" && props.machine?.meassurePerformance === false) {
            return `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})`;
        }
        else if (attributeName === "k" && props.machine?.meassureQuality === false) {
            return `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})`;
        }
        return value > percentWarning
            ? `linear-gradient(to right bottom, ${colors.dashbordGreen[600]}, ${colors.dashbordGreen[800]})`
            : value > percentBad && value <= percentWarning
                ? `linear-gradient(to right bottom, ${colors.yellow[500]}, ${colors.yellow[700]})`
                : `linear-gradient(to right bottom, ${colors.redAccent[500]}, ${colors.redAccent[700]})`
    }

    function handleGrafanaLinkClick() {
        if (props.machine?.useStopPageOnGrafanaLink)
            window.open(`/machineDashboard/${props.machine?.id}`, "_blank");
        else {
            window.open(grafanaLink, "_blank");
        }
    }

    function getItemInProduction(item, itemName) {
        if (item === "#NA" || item === "" || item === null || item === undefined) {
            return itemName;
        }
        return item + " - " + itemName;
    }

    function getItemInProductionTextSize(value) {
        if (value?.length > 35) {
            return "h4"
        }
        return "h3";
    }

    let content = <>
        <Box height="120px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="100px"
            gap="20px"
        >
            <Box
                gridColumn="span 2"
                sx={{
                    background:
                        machineStatus === "Running" ? `linear-gradient(to right bottom, ${colors.dashbordGreen[600]}, ${colors.dashbordGreen[800]})` :
                            machineStatus === "Offline" ? `linear-gradient(to right bottom, ${colors.yellow[500]}, ${colors.yellow[700]})` :
                                machineStatus === "Stopped" ? `linear-gradient(to right bottom, ${colors.redAccent[500]}, ${colors.redAccent[700]})` :
                                    `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})`
                }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.machineStatus} />
                </Box>
                <Box
                    justifyContent="center"
                    display="flex">
                    <Box>
                        <Typography variant={runningMachine ? "h1" : "h4"}>
                            {machineStatus}
                        </Typography>
                    </Box>

                </Box>
                {!runningMachine ?
                    <Box justifyContent="center" display="flex">
                        <Typography variant="h4">
                            {currentStop}
                        </Typography>
                    </Box>
                    : ""}
            </Box>

            <Box
                gridColumn="span 2"
                sx={{
                    background:
                        getBoxColor(t, "t")
                }}
            >
                <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Box><FormattedMessage {...messages.t} /></Box>
                    <Box><FormattedMessage {...messages.targetValue} /> {getAvalabilityThresholds().percentWarning}%</Box>
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Box alignItems="baseline" justifyContent="center" display="flex">
                        <Typography variant="h1">
                            {roundOrEmptystring(t, "t")}
                        </Typography>
                        <Typography variant="h2">
                            {showStringIfNotEmptyValue(t, "%", "t")}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                gridColumn="span 2"
                sx={{
                    background:
                        getBoxColor(a, "a")
                }}
            >
                <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Box><FormattedMessage {...messages.a} /></Box>
                    {props.machine?.meassurePerformance && <Box><FormattedMessage {...messages.targetValue} /> {getPerformanceThresholds().percentWarning}%</Box>}
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Box alignItems="baseline" justifyContent="center" display="flex">
                        <Typography variant="h1">
                            {roundOrEmptystring(a, "a")}
                        </Typography>
                        <Typography variant="h2">
                            {showStringIfNotEmptyValue(a, "%", "a")}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                gridColumn="span 2"
                sx={{
                    background:
                        getBoxColor(k, "k")
                }}
            >
                <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Box><FormattedMessage {...messages.k} /></Box>
                    {props.machine?.meassureQuality && <Box><FormattedMessage {...messages.targetValue} /> {getQualityThresholds().percentWarning}%</Box>}
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Box alignItems="baseline" justifyContent="center" display="flex">
                        <Typography variant="h1">
                            {roundOrEmptystring(k, "k")}
                        </Typography>
                        <Typography variant="h2">
                            {showStringIfNotEmptyValue(k, "%", "k")}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                gridColumn="span 2"
                sx={{
                    background:
                        getBoxColor(tak, "tak")
                }}
            >
                <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Box><FormattedMessage {...messages.tak} /></Box>
                    <Box><FormattedMessage {...messages.targetValue} /> {getOeeThresholds().percentWarning}%</Box>
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Box alignItems="baseline" justifyContent="center" display="flex">
                        <Typography variant="h1">
                            {roundOrEmptystring(tak)}
                        </Typography>
                        <Typography variant="h2">
                            {showStringIfNotEmptyValue(tak, "%")}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                gridColumn="span 2"
                gridRow="span 2"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                {/* <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.linksAndFunctions} />
                </Box> */}

                <Box display="flex" justifyContent="center" padding={1}>
                    <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.settingsButtonTooltip} /></Typography>}>
                        <IconButton aria-label="delete" size="large" onClick={() => { props.handleOpenSettingsClick(); }}>
                            <SettingsOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.commentButtonTooltip} /></Typography>}>
                        <IconButton aria-label="Open machine comment" size="large" onClick={() => { props.handleOpenCommentClick(); }}>
                            <ChatOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    {props.company?.grafanaBaseUrl && <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.grafanaButtonTooltip} /></Typography>}>
                        <IconButton size="large" onClick={handleGrafanaLinkClick} target="_blank">
                            <AnalyticsOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.plcLogButtonTooltip} /></Typography>}>
                        <IconButton size="large" onClick={() => { props.handlePlcLogClick(); }}>
                            <FormatListBulletedOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider color="white" />
                <Box sx={{ padding: 1 }} onClick={() => { props.handleOpenCommentClick(); }}>
                    <FormattedMessage {...messages.machineComments} />
                </Box>
                <textbutton onClick={() => { props.handleOpenCommentClick(); }} background="">
                    <Box sx={{ padding: 1 }}>

                        <Typography variant="h6">
                            {props.machineComment}
                        </Typography>
                    </Box>
                </textbutton>
            </Box>
        </Box>

        {/* --------- */}
        <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="100px"
            gap="20px"
        >
            <Box
                gridColumn="span 2"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.machine} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant="h1" component="h2">
                        {props.machineName}
                    </Typography>
                </Box>
            </Box>

            <Box
                gridColumn="span 4"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.itemInProduction} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant={getItemInProductionTextSize(getItemInProduction(machineInfo.activeItem, machineInfo.activeItemName))}
                        component="h3">
                        {getItemInProduction(machineInfo.activeItem, machineInfo.activeItemName)}
                    </Typography>
                </Box>
            </Box>

            <Box
                gridColumn="span 2"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.productionOrder} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant="h1" component="h2">
                        {machineInfo.activeProductionOrder}
                    </Typography>
                </Box>
            </Box>

            <Box
                gridColumn="span 2"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.percentageDone} />
                </Box>
                <Stack paddingLeft="10px"
                    paddingRight="10px"
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}>
                    {/* sx={{zIndex: 'modal', position: 'absolute'}} */}
                    <Box height="27px" justifyContent="right" width="70%">
                        <PercentageBar percentage={percentageDoneMax} />
                    </Box>
                    <Box alignItems="baseline" justifyContent="left" display="flex" paddingLeft={"10px"} Width="30%" >
                        <Typography variant="h2">
                            {percentageDone}
                        </Typography>
                        <Typography variant="h3">
                            {showStringIfNotEmptyValue(percentageDone, "%")}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            {/* <Box
                gridColumn="span 2"
               
            >...</Box> */}
        </Box>

        {/* ROW 2. */}

        {/* ROW 3 */}
        < Box
            display="grid"
            gridTemplateColumns="repeat(10, 1fr)"
            gridAutoRows="75px"
            gap="20px"
            paddingTop={"20px"}
            height="120px"
        >

            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}

            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.nrOfCycles} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant="h3">
                        {numberOfCyclesWithCavity}
                    </Typography>
                </Box>
            </Box>

            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.idealCycleTimeActive} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant="h3">
                        {idealCycleTime}
                    </Typography>
                </Box>
            </Box>

            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${cycleTimeColor(lastCycleTime, idealCycleTime)})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.lastCycleTime} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant="h3">
                        {lastCycleTime}
                    </Typography>
                </Box>
            </Box>
            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.plannedQty} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant="h3">
                        {machineInfo.plannedQty}
                    </Typography>
                </Box>
            </Box>

            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.reportedQty} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant="h3">
                        {machineInfo.reportedQty}
                    </Typography>
                </Box>
            </Box>

            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.remainingQty} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant="h3">
                        {machineInfo.remainingQty}
                    </Typography>
                </Box>
            </Box>

            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.scrappedQty} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Typography variant="h3">
                        {scrappedQty}
                    </Typography>
                </Box>
            </Box>

            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.remainingTime} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Box alignItems="baseline" justifyContent="center" display="flex">
                        <Typography variant="h3">
                            {remainingTimeRaw ? `${remainingTimeRaw.toFixed(2)} ${formatMessage(messages.hours)}` : "-"}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.uptime} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Box alignItems="baseline" justifyContent="center" display="flex">
                        <Typography variant="h3">
                            {getTimeRecalc(uptime)}
                        </Typography>
                        <Typography variant="h4" paddingLeft={1}>
                            {getTimeUnit(uptime) === "days" ? <FormattedMessage {...messages.days} /> : <>{getTimeUnit(uptime)}</>}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                gridColumn="span 1"
                sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}
            >
                <Box sx={{ padding: 1 }}>
                    <FormattedMessage {...messages.downtime} />
                </Box>
                <Box alignItems="center"
                    justifyContent="center"
                    display="flex">
                    <Box alignItems="baseline" justifyContent="center" display="flex">
                        <Typography variant="h3">
                            {getTimeRecalc(downTime)}
                        </Typography>
                        <Typography variant="h4" paddingLeft={1}>
                            {getTimeUnit(downTime) === "days" ? <FormattedMessage {...messages.days} /> : <>{getTimeUnit(downTime)}</>}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box >
    </>
    // }
    return (
        <>
            {content}

        </>
    )
}


export default Infoboxes;
