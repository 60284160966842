import { useQuery } from '@tanstack/react-query';
import { fetchFeatures } from '../fetchers/features';

const useFeatureFlags = () => {
  return useQuery({
    queryKey: ['featureFlags'],
    queryFn: () => fetchFeatures(),
    staleTime: 60000,
    refetchInterval: 60000
  });
};

export const isFeatureEnabled = (features, featureName) => {
    return features?.data?.some(f => f.name === featureName && f.isEnabled === true);
};

export default useFeatureFlags;