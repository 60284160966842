import React from "react";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { useTheme, Box, Paper, CircularProgress, IconButton } from "@mui/material";
import { tokens } from "../../../assets/theme";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";


export default function EmbeddedReportsDataGrid(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const apiRef = useGridApiRef();

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

    let columns = [
      { 
        field: "reportName", 
        headerName: <FormattedMessage {...messages.reportNameColumnLabel} />,
        flex: 1,
      },
      { 
        field: "embeddUrl", 
        headerName: <FormattedMessage {...messages.embeddedUrlColumnLabel} />,
        flex: 4,
      },
      { 
        field: "sortOrder", 
        headerName: <FormattedMessage {...messages.sortOrderColumnLabel} />,
        flex: 0.5,
      },
      {
        field:"edit", 
        headerName:"",
        renderCell:(params) => {
            return(
                <>
                <IconButton
                    arial-label={<FormattedMessage {...messages.editEmbeddedReportLabel} />}
                    size="small"
                    sx={{
                        "&:hover": {
                        backgroundColor: colors.primary[500],
                        },
                        backgroundColor: colors.primary[700],
                    }}
                    onClick={() => {
                        props.handleEditEmbeddedReportClick(params.row.id)
                    }}
                    >
                        <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    arial-label={<FormattedMessage {...messages.deleteEmbeddedReportLabel} />}
                    size="small"
                    sx={{
                        "&:hover": {
                        backgroundColor: colors.primary[500],
                        },
                        backgroundColor: colors.primary[700],
                        marginLeft: "10px"
                    }}
                    onClick={() => {
                      props.handleDeleteEmbeddedReportClick(params.row.id)
                    }}
                    >
                        <DeleteIcon fontSize="inherit" />
                </IconButton>
                </>
            )
        },
        width: 90,
      },
    ];
  
    const columnOrderLocalStorageName = 'embedded-reports-columnOrder';
    const columnWidthsLocalStorageName = 'embedded-reports-columnWidths';
    
    // Load persisted column order, widths, and sort order on mount
    function initColumnsFromLocalStorage(columns) {
      let columnsToFix = columns;
      // Load column order
      const savedColumnOrder = localStorage.getItem(columnOrderLocalStorageName);
      if (savedColumnOrder) {
        const orderArray = JSON.parse(savedColumnOrder);
        columnsToFix = reorderColumns(orderArray, columnsToFix);
      }

      // Load column widths
      const savedWidths = localStorage.getItem(columnWidthsLocalStorageName);
      if (savedWidths) {
          const widthMap = JSON.parse(savedWidths);
          columnsToFix = applyColumnWidths(widthMap, columnsToFix);
        }
      return columnsToFix;
    };


    // Reorder columns based on saved order
    const reorderColumns = (orderArray, columnsToFix) => {
      const reorderedColumns = orderArray.map((field) =>
          columnsToFix.find((col) => col.field === field)
      );
      reorderedColumns.push(...columnsToFix.filter((col) => !reorderedColumns.includes(col)));
      return reorderedColumns;
    };

    // Apply saved column widths
    const applyColumnWidths = (widthMap, columnsToFix) => {
      const updatedColumns = columnsToFix.map((col) => ({
        ...col,
        width: widthMap[col.field] || col.width, 
        flex: widthMap[col.field] !== undefined ? undefined : col.flex, 
      }));
      return updatedColumns;
    };

    // Handle column order change
    const handleColumnOrderChange = () => {
      if (!apiRef.current) {
        return;
      }
      const orderedColumns = apiRef.current.getVisibleColumns();
      const newColumnOrder = orderedColumns.map((col) => col.field);
      localStorage.setItem(columnOrderLocalStorageName, JSON.stringify(newColumnOrder));
    };

    // Handle column resize
    const handleColumnResize = (params) => {
      const { colDef, width } = params;
      const newWidths = JSON.parse(localStorage.getItem(columnWidthsLocalStorageName)) || {};
      newWidths[colDef.field] = width;
      localStorage.setItem(columnWidthsLocalStorageName, JSON.stringify(newWidths));
    };

    if(props.embeddedReports.isLoading) {
        return <Box padding={2}>
          <Paper
            sx={{
              position: "relative",
              borderRadius: "4px",
            }}
          >
            <CircularProgress color="secondary" />
          </Paper>
        </Box>
     }

     columns = initColumnsFromLocalStorage(columns);

     return (
      <Box padding={2} paddingTop={8}>
        <Paper
          sx={{
            position: "relative",
            borderRadius: "4px",
          }}
        >
          <Box 
            m="0px 0 0 0"
            height="76vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
              },
              "& .name.column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-cellContent": {
                fontSize: "13px",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.primary[600],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.primary[600],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.gray[100]} !important`,
              },
              "& .super-app-theme--11": {
                bgcolor: `${colors.redAccent[500]} !important`,
              },
              "& .super-app-theme--undefined-stop": {
                bgcolor: `${colors.redAccent[500]} !important`,
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "15px",
              },
            }}
          >
            { (!props.embeddedReports.isLoading) && <DataGridPro
              apiRef={apiRef}
              onColumnResize={handleColumnResize}
              onColumnOrderChange={handleColumnOrderChange}
              rows={props.embeddedReports.data}
              getRowId={ (row) => row.id }
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
            /> }
            { (props.embeddedReports.isLoading) && <CircularProgress color="secondary" />}
          </Box>
        </Paper>
      </Box>
    );
}