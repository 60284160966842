
/**
 * Represents the component for adding or editing recurring activities.
 * @param {Object} props - The component props.
 * @param {Object} props.recurringSchedule - The recurring schedule object.
 * @param {string} props.recurringSchedule.name - The name of the recurring schedule.
 * @param {string} props.recurringSchedule.instructionId - The ID of the selected instruction.
 * @param {string} props.recurringSchedule.machineId - The ID of the selected machine.
 * @param {number} props.recurringSchedule.scheduleType - The type of the schedule.
 * @param {string} props.recurringSchedule.reactCronExpression - The cron expression in React format.
 * @param {number} props.recurringSchedule.runningTimeInterval - The running time interval in hours.
 * @param {number} props.recurringSchedule.quantityInterval - The produced quantity interval.
 * @param {number} props.recurringSchedule.maxDuration - The maximum duration in hours to autoclose.
 * @param {Array} props.instructions - The list of instructions.
 * @param {Array} props.machines - The list of machines.
 * @param {Function} props.onChange - The function to handle changes in the form fields.
 * @param {Function} props.handleSave - The function to handle the save button click.
 * @returns {JSX.Element} The JSX element representing the recurring activity add/edit component.
 */
import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import AxionControls from "../../../components/controls/AxionControls";
import Cron from "../../../components/cronGenerator";
import { Button } from "reactstrap";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import FormLabel from "@mui/material/FormLabel";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

function RecurringActivityAddEdit(props) {
  const { formatMessage } = useIntl();
  const language = localStorage.getItem('language') || 'en'
  const [selectedInstruction, setSelectedInstruction] = React.useState("");
  const [selectedMachine, setSelectedMachine] = React.useState("");
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [cron, setCron] = useState("");
  const [scheduleType, setScheduleType] = useState(
    [
      { id: 0, title: formatMessage(messages.typeDate) },
      { id: 1, title: formatMessage(messages.typeRunningTime) },
      { id: 2, title: formatMessage(messages.typeQuantity) },
      { id: 3, title: formatMessage(messages.typeStopReasonCode) },
      { id: 4, title: formatMessage(messages.typeToolRunningTime) },
      { id: 5, title: formatMessage(messages.typeToolQuantity) },
      { id: 6, title: formatMessage(messages.typeItemRunningTime) },
      { id: 7, title: formatMessage(messages.typeItemQuantity) },
      { id: 8, title: formatMessage(messages.typeDepartment) },
    ]);
  
    function getScheduleType(type) {
      if (props.recurringSchedule.id > 0) {
        switch (type) {
          case 0:
            return [{ id: 0, title: formatMessage(messages.typeDate) }];
          case 1:
            return [{ id: 1, title: formatMessage(messages.typeRunningTime) }]
          case 2:
            return [{ id: 2, title: formatMessage(messages.typeQuantity) }];
          case 3:
            return [{ id: 3, title: formatMessage(messages.typeStopReasonCode) }];
          case 4:
            return [{ id: 4, title: formatMessage(messages.typeToolRunningTime) }];
          case 5:
            return [{ id: 5, title: formatMessage(messages.typeToolQuantity) }];
          case 6:
            return [{ id: 6, title: formatMessage(messages.typeItemRunningTime) }];
          case 7:
            return [{ id: 7, title: formatMessage(messages.typeItemQuantity) }];
          case 8:
            return [{ id: 8, title: formatMessage(messages.typeDepartment) }];
          default:
            //return [{ id: type, title: `Schedule type ${type} is undefined` }];
        };
      } else {
        return [
          { id: 0, title: formatMessage(messages.typeDate) },
          { id: 1, title: formatMessage(messages.typeRunningTime) },
          { id: 2, title: formatMessage(messages.typeQuantity) },
          { id: 3, title: formatMessage(messages.typeStopReasonCode) },
          { id: 4, title: formatMessage(messages.typeToolRunningTime) },
          { id: 5, title: formatMessage(messages.typeToolQuantity) },
          { id: 6, title: formatMessage(messages.typeItemRunningTime) },
          { id: 7, title: formatMessage(messages.typeItemQuantity) },
          { id: 8, title: formatMessage(messages.typeDepartment) },
        ];
      }
    }
  

  // This connects the cron language string to react-intl translations
  function getTranslation(key) {
    switch (key) {
      case 'Hourly':
        return formatMessage(messages.hourly);
      case 'Daily':
        return formatMessage(messages.daily);
      case 'Weekly':
        return formatMessage(messages.weekly);
      case 'Monthly':
        return formatMessage(messages.monthly);
      case 'Every':
        return formatMessage(messages.every);
      case 'hour(s)':
        return formatMessage(messages.hours);
      case 'At':
        return formatMessage(messages.at);
      case 'day(s)':
        return formatMessage(messages.days);
      case 'Every week day':
        return formatMessage(messages.everyWeekDay);
      case 'Start time':
        return formatMessage(messages.startTime);
      case 'Monday':
        return formatMessage(messages.monday);
      case 'Tuesday':
        return formatMessage(messages.tuesday);
      case 'Wednesday':
        return formatMessage(messages.wednesday);
      case 'Thursday':
        return formatMessage(messages.thursday);
      case 'Friday':
        return formatMessage(messages.friday);
      case 'Saturday':
        return formatMessage(messages.saturday);
      case 'Sunday':
        return formatMessage(messages.sunday);
      case 'Day':
        return formatMessage(messages.day);
      case 'of every month(s)':
        return formatMessage(messages.ofEveryMonth);
      case 'Last day of every month':
        return formatMessage(messages.lastDayOfEveryMonth);
      case 'On the last weekday of every month':
        return formatMessage(messages.lastWeekdayOfEveryMonth);
      case 'day(s) before the end of the month':
        return formatMessage(messages.daysBeforeTheEndOfTheMonth);
      default:
        return key; 
    }
  }

const handleCronChange = (cron) => {
  props.onChange({ target: { id: "cronExpression", value: cron } });
}
const handleReactCronChange = (cron) => {
  props.onChange({ target: { id: "reactCronExpression", value: cron } });
}

const convertCronToHangfireExpression = (value) => {
    // Split the enhanced expression into its components
    const parts = value.toString().split(" ");
    // Map the enhanced components to their traditional counterparts
    const traditionalParts = [
      parts[1], // Minute
      parts[2], // Hour
      parts[3], // Day of Month
      parts[4], // Month
      parts[5], // Day of Week
    ];

    // Join the traditional parts with spaces to form the traditional cron expression
    const traditionalExpression = traditionalParts.join(" ");
    setCron(traditionalExpression.replace(/!/g, ","));
    handleReactCronChange(value.toString());
    handleCronChange(traditionalExpression.replace(/!/g, ","));
  };

  const convertToReactCron = (value) => {
    // Split the enhanced expression into its components
    if (value === "") {
      return "";
    }
    value = value.replace(/,/g, "!");
    const parts = value.toString().split(" ");
    // Map the enhanced components to their traditional counterparts
    const traditionalParts = [
      parts[0], // Minute
      parts[1], // Hour
      parts[2], // Day of Month
      parts[3], // Month
      parts[4], // Day of Week
      parts[5], // Day of Week
      parts[6], // Day of Week
    ];

    // Join the traditional parts with spaces to form the traditional cron expression
    const reactExpression = traditionalParts.join(",");
    return reactExpression;
  };

  const filteredMachines = props.machines?.filter((machine) => { return machine.machineType?.showInActivitySpecialList === false });
  const filteredDepartments = props.machines?.filter((machine) => { return machine.machineType?.showInActivitySpecialList === true });

  return (
    <Box width="100%">
      <Grid container spacing={1}>
      <FormGrid item xs={12} md={3} sx={{ marginTop: "11px"  }}>
          <AxionControls.AxionSelect
            readOnly={props.recurringSchedule.id > 0}
            showNoneOption={false}
            size="small"
            name="scheduleType"
            value={props.recurringSchedule.scheduleType}
            label={<FormattedMessage {...messages.scheduleTypePlaceholder} />}
            onChange={props.onChange}
            options={getScheduleType(props.recurringSchedule.scheduleType)}
          />
        </FormGrid>
        <FormGrid item xs={12} md={9}></FormGrid>  

        <FormGrid item xs={12} md={3}>
          <AxionControls.AxionTextBox name="name" label={<FormattedMessage {...messages.activityNamePlaceholder} />} value={props.recurringSchedule.name} onChange={props.onChange} />
        </FormGrid>

        <FormGrid item xs={12} md={3} sx={{ marginTop: "11px" }}>
          <AxionControls.AxionSelect
            size="small"
            name="instructionId"
            value={props.recurringSchedule.instructionId}
            label={<FormattedMessage {...messages.instructionPlaceholder} />}
            onChange={props.onChange}
            showNoneOption={false}
            options={props.instructions}
          />
        </FormGrid>
        <FormGrid item xs={12} md={2}>
          <AxionControls.AxionTextBox inputProps={{ type: 'number', min:1}} name="maxDuration" label={<FormattedMessage {...messages.maxDurationPlaceholder} />} value={props.recurringSchedule.maxDuration } onChange={props.onChange} />
        </FormGrid>

        <FormGrid item xs={12} md={4}></FormGrid>
            
        {/* <AxionControls.AxionSelect size="small" value={props.name} label="Select Machine" onChange={handleMachineChange} options={props.machines} /> */}

        { props.recurringSchedule.scheduleType === 0 && 
          <>
          <FormGrid item xs={12} md={6} sx={{ marginTop: "12px" }}>
            <AxionControls.AxionAutocomplete
              //sx={{ height: "150px" }}
              name={props.recurringSchedule.multipleMachineIds === true ? "machineIds" : "machineId"}
              label={<FormattedMessage {...messages.machinePlaceholder} />}
              multiple={props.recurringSchedule.multipleMachineIds === true ? true : false}
              defaultValue={filteredMachines.filter((machine) => props.recurringSchedule?.machineId === machine.id).map((machine) => ({ id: machine.id, title: machine.machineName }))[0]}
              options={filteredMachines.map((machine) => ({
                id: machine.id,
                title: machine.machineName,
              }))}
              onChange={(event, newValue) => {
                props.recurringSchedule.multipleMachineIds === true ? props.onChange({ target: { id: "machineIds", value: newValue?.map(v => v.id) } }) : props.onChange({ target: { id: "machineId", value: newValue?.id } });
              }}
            />
          </FormGrid> 
          <FormGrid item xs={12} sx={{ marginTop: "12px" }}>
            <FormLabel htmlFor="cron" required>
            <FormattedMessage {...messages.createSchedule} />
            </FormLabel>
            <Cron value={convertToReactCron(props.recurringSchedule.reactCronExpression)} onChange={(e) => convertCronToHangfireExpression(e)} locale={language} translateFn={getTranslation} />
          </FormGrid> </>}
        { props.recurringSchedule.scheduleType === 1 && 
          <>
          <FormGrid item xs={12} md={6} sx={{ marginTop: "20px" }}>
            <AxionControls.AxionAutocomplete
                name={props.recurringSchedule.multipleMachineIds === true ? "machineIds" : "machineId"}
                label={<FormattedMessage {...messages.machinePlaceholder} />}
                multiple={props.recurringSchedule.multipleMachineIds === true ? true : false}
                defaultValue={filteredMachines.filter((machine) => props.recurringSchedule?.machineId === machine.id).map((machine) => ({ id: machine.id, title: machine.machineName }))[0]}
                options={filteredMachines.map((machine) => ({
                  id: machine.id,
                  title: machine.machineName,
                }))}
                onChange={(event, newValue) => {
                  props.recurringSchedule.multipleMachineIds === true ? props.onChange({ target: { id: "machineIds", value: newValue?.map(v => v.id) } }) : props.onChange({ target: { id: "machineId", value: newValue?.id } });
                }}
              />
          </FormGrid> 
          <FormGrid item xs={4} sx={{marginTop: "10px"}}>
            <AxionControls.AxionTextBox 
                name="runningTimeInterval" 
                sx={{ 
                  '& .MuiFilledInput-input' : { height: '28px' }, 
                  }}
                label={<FormattedMessage {...messages.runningTimeInterval} />} 
                value={props.recurringSchedule.runningTimeInterval} 
                onChange={props.onChange} />
          </FormGrid> 
          </>}
        { props.recurringSchedule.scheduleType === 2 && 
          <>
          <FormGrid item xs={12} md={6} sx={{ marginTop: "20px" }}>
            <AxionControls.AxionAutocomplete
                name={props.recurringSchedule.multipleMachineIds === true ? "machineIds" : "machineId"}
                label={<FormattedMessage {...messages.machinePlaceholder} />}
                multiple={props.recurringSchedule.multipleMachineIds === true ? true : false}
                defaultValue={filteredMachines.filter((machine) => props.recurringSchedule?.machineId === machine.id).map((machine) => ({ id: machine.id, title: machine.machineName }))[0]}
                options={filteredMachines.map((machine) => ({
                  id: machine.id,
                  title: machine.machineName,
                }))}
                onChange={(event, newValue) => {
                  props.recurringSchedule.multipleMachineIds === true ? props.onChange({ target: { id: "machineIds", value: newValue?.map(v => v.id) } }) : props.onChange({ target: { id: "machineId", value: newValue?.id } });
                }}
              />
          </FormGrid> 
          <FormGrid item xs={4} sx={{marginTop: "10px"}}>
            <AxionControls.AxionTextBox 
                name="quantityInterval" 
                sx={{ 
                  '& .MuiFilledInput-input' : { height: '28px' }, 
                  }}
                label={<FormattedMessage {...messages.quantityInterval} />} 
                value={props.recurringSchedule.quantityInterval} 
                onChange={props.onChange} />
          </FormGrid> 
          </>}
        { props.recurringSchedule.scheduleType === 3 && 
          <>
          <FormGrid item xs={12} md={6} sx={{ marginTop: "20px" }}>
            <AxionControls.AxionAutocomplete
                name={props.recurringSchedule.multipleMachineIds === true ? "machineIds" : "machineId"}
                label={<FormattedMessage {...messages.machinePlaceholder} />}
                multiple={props.recurringSchedule.multipleMachineIds === true ? true : false}
                defaultValue={filteredMachines.filter((machine) => props.recurringSchedule?.machineId === machine.id).map((machine) => ({ id: machine.id, title: machine.machineName }))[0]}
                options={filteredMachines.map((machine) => ({
                  id: machine.id,
                  title: machine.machineName,
                }))}
                onChange={(event, newValue) => {
                  props.recurringSchedule.multipleMachineIds === true ? props.onChange({ target: { id: "machineIds", value: newValue?.map(v => v.id) } }) : props.onChange({ target: { id: "machineId", value: newValue?.id } });
                }}
              />
          </FormGrid> 
          <FormGrid item xs={6} sx={{marginTop: "20px"}}>
            { props.stopReasonCodes && <AxionControls.AxionSelect
            sx={{ 
              '& .MuiFilledInput-input' : { height: '28px' } }} 
            name="stopReasonCodeId"
            value={props.recurringSchedule.stopReasonCodeId}
            label={<FormattedMessage {...messages.selectStopReasonCode} />}
            onChange={props.onChange}
            options={props.stopReasonCodes.map((stopReasonCode) => ({
              id: stopReasonCode.id,
              title: stopReasonCode.name,
            }))}
          /> }
          </FormGrid> </>}
          { props.recurringSchedule.scheduleType === 4 && 
          <>
            <FormGrid item xs={6} sx={{ marginTop: "20px" }}>
              <AxionControls.AxionAutocomplete
                name={props.recurringSchedule.multipleToolIds === true ? "toolIds" : "toolId"}
                label={<FormattedMessage {...messages.toolPlaceholder} />}
                multiple={props.recurringSchedule.multipleToolIds === true ? true : false}
                defaultValue={props.tools.filter((tool) => props.recurringSchedule?.toolId === tool.id).map((tool) => ({ id: tool.id, title: tool.name }))[0]}
                options={props.tools.map((tool) => ({
                  id: tool.id,
                  title: `${tool.name} - ${tool.description}`,
                }))}
                onChange={(event, newValue) => {
                  props.recurringSchedule.multipleToolIds === true ? props.onChange({ target: { id: "toolIds", value: newValue?.map(v => v.id) } }) : props.onChange({ target: { id: "toolId", value: newValue?.id } });
                }}
              />
            </FormGrid> 
            <FormGrid item xs={4} sx={{marginTop: "10px"}}>
                <AxionControls.AxionTextBox 
                    name="runningTimeInterval" 
                    sx={{ 
                      '& .MuiFilledInput-input' : { height: '28px' }, 
                      }}
                    label={<FormattedMessage {...messages.runningTimeInterval} />} 
                    value={props.recurringSchedule.runningTimeInterval} 
                    onChange={props.onChange} />
            </FormGrid> 
          </>}
          { props.recurringSchedule.scheduleType === 5 && 
          <>
            <FormGrid item xs={6} sx={{ marginTop: "20px" }}>
              <AxionControls.AxionAutocomplete
                  name={props.recurringSchedule.multipleToolIds === true ? "toolIds" : "toolId"}
                  label={<FormattedMessage {...messages.toolPlaceholder} />}
                  multiple={props.recurringSchedule.multipleToolIds === true ? true : false}
                  defaultValue={props.tools.filter((tool) => props.recurringSchedule?.toolId === tool.id).map((tool) => ({ id: tool.id, title: tool.name }))[0]}
                  options={props.tools.map((tool) => ({
                    id: tool.id,
                    title: `${tool.name} - ${tool.description}`,
                  }))}
                  onChange={(event, newValue) => {
                    props.recurringSchedule.multipleToolIds === true ? props.onChange({ target: { id: "toolIds", value: newValue?.map(v => v.id) } }) : props.onChange({ target: { id: "toolId", value: newValue?.id } });
                  }}
                />
            </FormGrid> 
          <FormGrid item xs={4} sx={{marginTop: "10px"}}>
            <AxionControls.AxionTextBox 
                  name="quantityInterval" 
                  sx={{ 
                    '& .MuiFilledInput-input' : { height: '28px' }, 
                    }}
                  label={<FormattedMessage {...messages.quantityInterval} />} 
                  value={props.recurringSchedule.quantityInterval} 
                  onChange={props.onChange} />
          </FormGrid> 
          </>}
          { props.recurringSchedule.scheduleType === 6 && 
          <>
              <FormGrid item xs={6} sx={{ marginTop: "20px" }}>
                <AxionControls.AxionAutocomplete
                  name={props.recurringSchedule.multipleToolIds === true ? "itemIds" : "itemId"}
                  label={<FormattedMessage {...messages.productionItemPlaceholder} />}
                  multiple={props.recurringSchedule.multipleItemIds === true ? true : false}
                  defaultValue={props.productionItems?.filter((item) => props.recurringSchedule?.itemId === item.id).map((item) => ({ id: item.id, title: item.name }))[0]}
                  options={props.productionItems?.map((item) => ({
                    id: item.id,
                    title: `${item.itemNo} - ${item.name}`,
                  }))}
                  onChange={(event, newValue) => {
                    props.recurringSchedule.multipleItemIds === true ? props.onChange({ target: { id: "itemIds", value: newValue?.map(v => v.id) } }) : props.onChange({ target: { id: "itemId", value: newValue?.id } });
                  }}
                />
            </FormGrid> 
            <FormGrid item xs={4} sx={{marginTop: "10px"}}>
                <AxionControls.AxionTextBox 
                    name="runningTimeInterval" 
                    sx={{ 
                      '& .MuiFilledInput-input' : { height: '28px' }, 
                      }}
                    label={<FormattedMessage {...messages.runningTimeInterval} />}
                    value={props.recurringSchedule.runningTimeInterval} 
                    onChange={props.onChange} />
            </FormGrid> 
          </>}
          { props.recurringSchedule.scheduleType === 7 && 
          <>
            <FormGrid item xs={6} sx={{ marginTop: "20px" }}>
              <AxionControls.AxionAutocomplete
                    name={props.recurringSchedule.multipleToolIds === true ? "itemIds" : "itemId"}
                    label={<FormattedMessage {...messages.productionItemPlaceholder} />}
                    multiple={props.recurringSchedule.multipleItemIds === true ? true : false}
                    defaultValue={props.productionItems?.filter((item) => props.recurringSchedule?.itemId === item.id).map((item) => ({ id: item.id, title: item.name }))[0]}
                    options={props.productionItems?.map((item) => ({
                      id: item.id,
                      title: `${item.itemNo} - ${item.name}`,
                    }))}
                    onChange={(event, newValue) => {
                      props.recurringSchedule.multipleItemIds === true ? props.onChange({ target: { id: "itemIds", value: newValue?.map(v => v.id) } }) : props.onChange({ target: { id: "itemId", value: newValue?.id } });
                    }}
                  />
            </FormGrid> 
            <FormGrid item xs={4} sx={{marginTop: "10px"}}>
              <AxionControls.AxionTextBox 
                    name="quantityInterval" 
                    sx={{ 
                      '& .MuiFilledInput-input' : { height: '28px' }, 
                      }}
                    label={<FormattedMessage {...messages.quantityInterval} />} 
                    value={props.recurringSchedule.quantityInterval} 
                    onChange={props.onChange} />
            </FormGrid> 
          </>}
          { props.recurringSchedule.scheduleType === 8 && 
          <>
          <FormGrid item xs={12} md={6} sx={{ marginTop: "12px" }}>
            <AxionControls.AxionAutocomplete
              //sx={{ height: "150px" }}
              name="machineId"
              label={<FormattedMessage {...messages.departmentPlaceholder} />}
              defaultValue={filteredDepartments.filter((machine) => props.recurringSchedule?.machineId === machine.id).map((machine) => ({ id: machine.id, title: machine.machineName }))[0]}
              options={filteredDepartments.map((machine) => ({
                id: machine.id,
                title: machine.machineName,
              }))}
              onChange={(event, newValue) => {
                  props.onChange({ target: { id: "machineId", value: newValue?.id } });
              }}
            />
          </FormGrid> 
          <FormGrid item xs={12} sx={{ marginTop: "12px" }}>
            <FormLabel htmlFor="cron" required>
            <FormattedMessage {...messages.createSchedule} />
            </FormLabel>
            <Cron value={convertToReactCron(props.recurringSchedule.reactCronExpression)} onChange={(e) => convertCronToHangfireExpression(e)} locale={language} translateFn={getTranslation} />
          </FormGrid> </>}
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Button
          // variant="contained"
          sx={{
            marginTop: 2,
            color: 'white',
            backgroundColor: "#799c4a !important",
            "&:hover": {
              backgroundColor: "#5e7939 !important",
              borderColor: "#5e7939 !important",
              boxShadow: "none",
            },
          }}
          onClick={props.handleSave}
        >
          <SaveOutlinedIcon /> <FormattedMessage {...messages.save} />
        </Button>
      </Box>
    </Box>
  );
}

export default RecurringActivityAddEdit;
