import axios from "axios";

export const fetchFeatures = async () => {
    const response = await axios.get("api/features/");
    const features = response.data;
    return features;
}

export const fetchFeature = async (name) => {
    const response = await axios.get(`api/features/${name}`);
    const feature = response.data;
    return feature;
}