import React, { useState, useEffect } from "react";
import { Box, Paper, useTheme, IconButton, Link } from '@mui/material';
import { tokens } from "../../../assets/theme";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import AxionControls from "../../../components/controls/AxionControls";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

export default function ActivityDepartmentGrid(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate(); 
    const apiRef = useGridApiRef();
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [sortModelStorage, setSortModelStorage] = useState([
        { field: "machineName", sort: "asc" }
      ]);

    const filteredMachines = props.machineResult?.data.filter((machine) => { return machine.machineType?.showInActivitySpecialList === true });

    let columns = [
        { 
          field: "machineName", 
          headerName: "Name",
          flex: 0.5,
          renderCell: ({ id, value, field }) => {
            return <Link href={`/machines/${id}`} sx={{color: '#fff', textDecoration: 'underline'}}>{value}</Link>
          },
        },
        { 
          field: "description", 
          headerName: "Description",
          flex: 2,
        },
        { 
          field: "machineTypeName", 
          headerName: "Type",
          flex: 2,
          valueGetter: ({ row }) => row.machineType?.machineTypeName
        },
        {
          field:"view", 
          headerName:"",
          flex: 1,
          renderCell:(params) => {
              return(
                  <>
                    <IconButton
                      arial-label={<FormattedMessage {...messages.editSettings} />}
                      size="small"
                      sx={{
                          "&:hover": {
                          backgroundColor: colors.primary[500],
                          },
                          backgroundColor: colors.primary[700],
                          marginLeft: "10px",
                      }}
                      onClick={() => {
                          props.handleEditSettingsClick(params.row.id);
                      }}
                      >
                          <SettingsOutlinedIcon fontSize="inherit" />
                    </IconButton>
                </>
              )
          },
        },
      ];

      const columnOrderLocalStorageName = 'activity-department-columnOrder';
      const columnWidthsLocalStorageName = 'activity-department-columnWidths';
      
    // Load persisted column order, widths, and sort order on mount
    function initColumnsFromLocalStorage(columns) {
      let columnsToFix = columns;
      // Load column order
      const savedColumnOrder = localStorage.getItem(columnOrderLocalStorageName);
      if (savedColumnOrder) {
        const orderArray = JSON.parse(savedColumnOrder);
        columnsToFix = reorderColumns(orderArray, columnsToFix);
      }

      // Load column widths
      const savedWidths = localStorage.getItem(columnWidthsLocalStorageName);
      if (savedWidths) {
          const widthMap = JSON.parse(savedWidths);
          columnsToFix = applyColumnWidths(widthMap, columnsToFix);
        }
      return columnsToFix;
    };
  
  
      // Reorder columns based on saved order
      const reorderColumns = (orderArray, columnsToFix) => {
        const reorderedColumns = orderArray.map((field) =>
            columnsToFix.find((col) => col.field === field)
        );
        reorderedColumns.push(...columnsToFix.filter((col) => !reorderedColumns.includes(col)));
        return reorderedColumns;
      };
  
      // Apply saved column widths
      const applyColumnWidths = (widthMap, columnsToFix) => {
        const updatedColumns = columnsToFix.map((col) => ({
          ...col,
          width: widthMap[col.field] || col.width, 
          flex: widthMap[col.field] !== undefined ? undefined : col.flex, 
        }));
        return updatedColumns;
      };
  
      // Handle column order change
      const handleColumnOrderChange = () => {
        if (!apiRef.current) {
          return;
        }
        const orderedColumns = apiRef.current.getVisibleColumns();
        const newColumnOrder = orderedColumns.map((col) => col.field);
        localStorage.setItem(columnOrderLocalStorageName, JSON.stringify(newColumnOrder));
      };
  
      // Handle column resize
      const handleColumnResize = (params) => {
        const { colDef, width } = params;
        const newWidths = JSON.parse(localStorage.getItem(columnWidthsLocalStorageName)) || {};
        newWidths[colDef.field] = width;
        localStorage.setItem(columnWidthsLocalStorageName, JSON.stringify(newWidths));
      };

    if(props.machineResult?.isLoading) {
        return <AxionControls.AxionBackdrop open={true} />
    }

    columns = initColumnsFromLocalStorage(columns);

    return (
        <Box padding={2}>
          <Paper
            sx={{
              position: "relative",
              borderRadius: "4px",
            }}
          >
            <Box 
              m="0px 0 0 0"
              height="76vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                },
                "& .name.column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-cellContent": {
                  fontSize: "13px",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.primary[600],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.primary[600],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.gray[100]} !important`,
                },
                "& .super-app-theme--11": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .super-app-theme--undefined-stop": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .MuiDataGrid-columnHeader": {
                  fontSize: "15px",
                },
              }}
            >
              <DataGridPro
                apiRef={apiRef}
                onColumnResize={handleColumnResize}
                onColumnOrderChange={handleColumnOrderChange}
                rows={filteredMachines}
                sortModel={sortModelStorage}
                onSortModelChange={(newModel) => setSortModelStorage(newModel)}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              />
            </Box>
          </Paper>
        </Box>
    );
}
