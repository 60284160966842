
/**
 * Renders a grid of recurring activities.
 * @param {Object} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
import React, { useEffect, useState } from "react";
import {
  fetchAllInstructions,
  fetchMachines,
  deleteScheduleRecurring,
  updateScheduleRecurring,
  createScheduleRecurring
} from "../hooks/fetchers";
import { fetchTools } from "../../../fetchers/tools";
import Controls from "../../../components/controls/Contols";
import AxionControls from "../../../components/controls/AxionControls";
import RecurringAcitvityAddEdit from "./recurringActivityAddEdit";
import { useQuery } from "@tanstack/react-query";
import { Box, CircularProgress,useTheme, IconButton, Typography, Stack, Button } from "@mui/material";
import { useMsal, useAccount } from "@azure/msal-react";
import { pushErrorMessage } from "../../../utils/notifications";
import Popup from "../../../components/Popup";
import { tokens } from "../../../assets/theme";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import cronstrue from 'cronstrue/i18n';
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { getStopReasonCodes } from "../../stopReasonCodes/hooks/fetchers";
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { fetchProductionItems } from "../../../fetchers/productionItems";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

function RecurringActivitiesGrid(props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const theme = useTheme();
  const apiRef = useGridApiRef();
  const colors = tokens(theme.palette.mode);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
  const language = localStorage.getItem('language') || 'en'
  const [recurringSchedule, setRecuringSchedule] = useState({
    id: "",
    name: "",
    cronExpression: "",
    instructionId: "",
    machineId: "",
    toolId: 0,
    itemNo: "",
    reactCronExpression: "",
    scheduleType: 0,
    maxDuration:1,
    companyKey: companyKey
  });
  const { formatMessage } = useIntl();
  const columnOrderLocalStorageName = 'recurring-activities-columnOrder';
  const columnWidthsLocalStorageName = 'recurring-activities-columnWidths';
  
  // Load persisted column order, widths, and sort order on mount
  function initColumnsFromLocalStorage(columns) {
    let columnsToFix = columns;
    // Load column order
    const savedColumnOrder = localStorage.getItem(columnOrderLocalStorageName);
    if (savedColumnOrder) {
      const orderArray = JSON.parse(savedColumnOrder);
      columnsToFix = reorderColumns(orderArray, columnsToFix);
    }

    // Load column widths
    const savedWidths = localStorage.getItem(columnWidthsLocalStorageName);
    if (savedWidths) {
        const widthMap = JSON.parse(savedWidths);
        columnsToFix = applyColumnWidths(widthMap, columnsToFix);
      }
    return columnsToFix;
  };


  // Reorder columns based on saved order
  const reorderColumns = (orderArray, columnsToFix) => {
    const reorderedColumns = orderArray.map((field) =>
        columnsToFix.find((col) => col.field === field)
    );
    reorderedColumns.push(...columnsToFix.filter((col) => !reorderedColumns.includes(col)));
    return reorderedColumns;
  };

  // Apply saved column widths
  const applyColumnWidths = (widthMap, columnsToFix) => {
    const updatedColumns = columnsToFix.map((col) => ({
      ...col,
      width: widthMap[col.field] || col.width, 
      flex: widthMap[col.field] !== undefined ? undefined : col.flex, 
    }));
    return updatedColumns;
  };

  // Handle column order change
  const handleColumnOrderChange = () => {
    if (!apiRef.current) {
      return;
    }
    const orderedColumns = apiRef.current.getVisibleColumns();
    const newColumnOrder = orderedColumns.map((col) => col.field);
    localStorage.setItem(columnOrderLocalStorageName, JSON.stringify(newColumnOrder));
  };

  // Handle column resize
  const handleColumnResize = (params) => {
    const { colDef, width } = params;
    const newWidths = JSON.parse(localStorage.getItem(columnWidthsLocalStorageName)) || {};
    newWidths[colDef.field] = width;
    localStorage.setItem(columnWidthsLocalStorageName, JSON.stringify(newWidths));
  };

  const instructionsQuery = useQuery(
    ["instructions"],
    () => fetchAllInstructions(companyKey),
    {
      staleTime: 60000,
      refetchInterval: 120000,
    }
  );
  
  const machinesQuery = useQuery(["machines", companyKey], () => fetchMachines(companyKey), {
    staleTime: 60000,
    refetchInterval: 120000,
  });

  const toolsQuery = useQuery(["tools", companyKey], () => fetchTools(companyKey), {
    staleTime: 60000,
    refetchInterval: 120000,
  });

  const productionItemsQuery = useQuery(["productionItems", companyKey], () => fetchProductionItems(companyKey), {
    staleTime: 60000,
    refetchInterval: 120000,
  });

  const stopReasonCodesQuery = useQuery(["stopReasonCodes", companyKey], () => getStopReasonCodes(companyKey), {
    staleTime: 60000
  });

  const handleChange = (event) => {
    const { id, name, value, type, checked } = event.target;
    setRecuringSchedule((prev) => {
      return {
        ...prev,
        [id ? id : name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleMachineIdChange = (event, newValue) => {
    console.log(newValue);
    setRecuringSchedule((prev) => {
      return {
        ...prev,
        machineIds: newValue.map((x) => x.id),
      };
    });
  };

  // useEffect(() => {
  //   setFilteredStopReasonCodes(stopReasonCodesQuery.data.filter(x => props.machine.machineStopReasonCodes.some(y => x.id === y.stopReasonCodeId)));
  //   }, [stopReasonCodesQuery.data, props.machine.machineStopReasonCodes]);

  const handleDelete = (id) => {
    setShowBackdrop(true);
    deleteScheduleRecurring(id).then(() => {
      props.recurringSchedules.refetch();
      setShowBackdrop(false);
    })
    .catch((error) => {
      console.log(error);
      setShowBackdrop(false);
      pushErrorMessage(`Cannot delete the recurring activity`);
    });
  }

  const handleAddRecurringScheduleClick = () => {
    setRecuringSchedule({
      id: "",
      name: "",
      cronExpression: "",
      instructionId: "",
      machineIds: [],
      multipleMachineIds: true,
      toolId: null,
      toolIds: [],
      multipleToolIds: true,
      itemId: null,
      itemIds: [],
      multipleItemIds: true,
      reactCronExpression: "",
      scheduleType: 0,
      maxDuration:1,
      companyKey: companyKey
    });
    setOpenPopup(true);
  }
  const handleEditRecurringScheduleClick = (id) => {
    const recurringSchedule = props.recurringSchedules.data.find((rowData) => rowData.id === id);
    setRecuringSchedule(recurringSchedule);
    setOpenPopup(true);
  }

  const handleSave = () => {
    setShowBackdrop(true);
    if (recurringSchedule.id) {
   
      updateScheduleRecurring(recurringSchedule)
        .then(() => {
          setOpenPopup(false);
          props.recurringSchedules.refetch();
          setShowBackdrop(false);
        })
        .catch((error) => {
          console.log(error);
          setShowBackdrop(false);
          pushErrorMessage(`Cannot update the recurring schedule.`);
        });
    } else {
      delete recurringSchedule.id;
      createScheduleRecurring(recurringSchedule)
        .then(() => {
          setOpenPopup(false);
          props.recurringSchedules.refetch();
          setShowBackdrop(false);
        })
        .catch((error) => {
          console.log(error);
          setShowBackdrop(false);
          pushErrorMessage(`Cannot create the recurring activity.`);
        });
    }
  };

 
 

  let columns = [
    { 
      field: "name", 
      headerName: <FormattedMessage {...messages.activityName} />,
      flex: 0.5,
      minWidth: 100,
    },
    { 
      field: "machineId", 
      headerName: <FormattedMessage {...messages.machineName} />,
      flex: 0.3,
      maxWidht: 100,
      valueGetter: ({ row: { machineId } }) => {return machinesQuery?.data?.filter(x => x.id === machineId).map(x => x.machineName)}
    },
    { 
      field: "toolId", 
      headerName: <FormattedMessage {...messages.toolName} />,
      flex: 0.3,
      maxWidht: 100,
      valueGetter: ({ row: { toolId } }) => {return toolsQuery?.data?.filter(x => x.id === toolId).map(x => x.name)}
    },
    { 
      field: "itemId", 
      headerName: <FormattedMessage {...messages.itemName} />,
      flex: 0.3,
      maxWidht: 100,
      valueGetter: ({ row: { itemId } }) => {return productionItemsQuery?.data?.filter(x => x.id === itemId).map(x => x.itemNo)}
    },
    { 
      field: "instructionId", 
      headerName: <FormattedMessage {...messages.instructionName} />,
      flex: 0.5,
      minWidth: 100,
      valueGetter: ({ row: { instructionId } }) => {return instructionsQuery?.data?.filter(x => x.id === instructionId).map(x => x.title)}
    },
    { 
      field: "cronExpression", 
      headerName:<FormattedMessage {...messages.schedule} />,
      minWidth: 300,
      flex: 1,
      valueGetter: ({ row: { cronExpression, scheduleType, runningTimeInterval, quantityInterval, stopReasonCodeId, toolId, itemId  } }) => {
           switch(scheduleType) {
          case 0:
            return cronstrue.toString(cronExpression, { locale: language });
          case 1:
            return formatMessage(messages.runningTimeIntervalSchedule).replace('{runningTimeInterval}', runningTimeInterval);
          case 2:
            return formatMessage(messages.quantityIntervalSchedule).replace('{quantityInterval}', quantityInterval);
          case 3:
            return formatMessage(messages.stopReasonCodeSchedule).replace('{stopReasonCode}', stopReasonCodesQuery?.data?.filter(x => x.id === stopReasonCodeId).map(x => x.name));
          case 4:
            return formatMessage(messages.toolRunningTimeIntervalSchedule).replace('{runningTimeInterval}', runningTimeInterval).replace('{toolName}', toolsQuery?.data?.filter(x => x.id === toolId).map(x => x.name));
          case 5:
            return formatMessage(messages.toolQuantityIntervalSchedule).replace('{quantityInterval}', quantityInterval).replace('{toolName}', toolsQuery?.data?.filter(x => x.id === toolId).map(x => x.name));
          case 6:
            return formatMessage(messages.itemRunningTimeIntervalSchedule).replace('{runningTimeInterval}', runningTimeInterval).replace('{itemName}', productionItemsQuery?.data?.filter(x => x.id === itemId).map(x => x.itemNo));
          case 7:
            return formatMessage(messages.itemQuantityIntervalSchedule).replace('{quantityInterval}', quantityInterval).replace('{itemName}', productionItemsQuery?.data?.filter(x => x.id === itemId).map(x => x.itemNo));
          case 8:
            return cronstrue.toString(cronExpression, { locale: language });
          default:
            return '';
        };
      }
    },
    {
      field:"edit", 
      headerName:"",
      renderCell:(params) => {
          return(
              <>
              <IconButton
                  arial-label="Edit stop reason code"
                  size="small"
                  sx={{
                      "&:hover": {
                      backgroundColor: colors.primary[500],
                      },
                      backgroundColor: colors.primary[700],
                  }}
                  onClick={() => {
                      handleEditRecurringScheduleClick(params.row.id)
                  }}
                  >
                      <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                  arial-label="Delete stop reason code"
                  size="small"
                  sx={{
                      "&:hover": {
                      backgroundColor: colors.primary[500],
                      },
                      backgroundColor: colors.primary[700],
                      marginLeft: "10px"
                  }}
                  onClick={() => {
                    handleDelete(params.row.id)
                  }}
                  >
                      <DeleteIcon fontSize="inherit" />
              </IconButton>
              </>
          )
      },
      width: 90,
    },
    ];


  var machineInfo = machinesQuery.data;
  var instructions = instructionsQuery.data;

  if (instructionsQuery.isLoading || machinesQuery.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  columns = initColumnsFromLocalStorage(columns);

  return (
    <Box width="98%" mr={8} ml={2}>
      {/* <RecurringAcitvityAddEdit onChange={handleChange} handleSave={handleSave} recurringSchedule={recurringSchedule} instructions = {instructions} machines = {machineInfo} setOpenPopup={props.setOpenPopup}  /> */}
      <Controls.Button
          text={<FormattedMessage {...messages.addRecurringActivity} />}
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => {
            handleAddRecurringScheduleClick();
          }}
          sx={{
            "&:hover": {
              backgroundColor: "#799c4a",
            },
            backgroundColor: "#799c4a",
            color: "#ffffff",
            position: "absolute",
            right: "10px",
          }}
        />
      <Box 
            m="20px 0 0 0"
            p="40px 0 0 0"
            height="76vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
              },
              "& .name.column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-cellContent": {
                fontSize: "13px",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.primary[600],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.primary[600],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.gray[100]} !important`,
              },
              "& .super-app-theme--11": {
                bgcolor: `${colors.redAccent[500]} !important`,
              },
              "& .super-app-theme--undefined-stop": {
                bgcolor: `${colors.redAccent[500]} !important`,
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "15px",
              },
            }}
          >
            { (!props.recurringSchedules.isLoading) && <DataGridPro
              apiRef={apiRef}
              onColumnResize={handleColumnResize}
              onColumnOrderChange={handleColumnOrderChange}
              rows={props.recurringSchedules.data}
              getRowId={ (row) => row.id }
              columns={columns}
              rowHeight={38}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
            /> }
            { (props.recurringSchedules.isLoading) && <CircularProgress color="secondary" />}
          </Box>

      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidth="lg"
        title={<FormattedMessage {...messages.addRecurringActivity} />}
      >
        <RecurringAcitvityAddEdit
          onChange={handleChange}
          onMachineIdChange={handleMachineIdChange}
          handleSave={handleSave}
          recurringSchedule={recurringSchedule}
          instructions={instructions}
          machines={machineInfo}
          setOpenPopup={props.setOpenPopup}
          stopReasonCodes={stopReasonCodesQuery?.data}
          tools={toolsQuery?.data}
          productionItems={productionItemsQuery?.data}
        />
      </Popup>
      <AxionControls.AxionBackdrop open={showBackdrop} />
    </Box>
  );
}

export default RecurringActivitiesGrid;
