import React from "react";
import { useState, useEffect } from "react";
import { Box, Paper, useTheme, IconButton, Link } from '@mui/material';
import { tokens } from "../../../assets/theme";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import AxionControls from "../../../components/controls/AxionControls";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

export default function MachineTypesGrid(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate(); 
    const apiRef = useGridApiRef();
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [sortModelStorage, setSortModelStorage] = useState([
        { field: "id", sort: "asc" }
      ]);

      const [columns, setColumns] = useState([
        { 
            field: "id", 
            headerName: <FormattedMessage {...messages.idColumn} />,
            flex: 0.3,
        },
        { 
          field: "machineTypeName", 
          headerName: <FormattedMessage {...messages.nameColumn} />,
          flex: 1,
        },
        { 
          field: "description", 
          headerName: <FormattedMessage {...messages.descriptionColumn} />,
          flex: 1,
        },
        { 
          field: "showInGrid", 
          headerName: <FormattedMessage {...messages.showInGridColumn} />,
          flex: 0.5,
          align: "center",
          renderCell: (params) => (
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: params.value ? "green" : "red",
                borderRadius: 1
              }}
            />
          ),
        },
        { 
            field: "showInGantt", 
            headerName: <FormattedMessage {...messages.showInGanttColumn} />,
            flex: 0.5,
            align: "center",
            renderCell: (params) => (
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: params.value ? "green" : "red",
                  borderRadius: 1
                }}
              />
            ),
          },
          { 
            field: "showInMachineList", 
            headerName: <FormattedMessage {...messages.showInMachineListColumn} />,
            flex: 0.5,
            align: "center",
            renderCell: (params) => (
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: params.value ? "green" : "red",
                  borderRadius: 1
                }}
              />
            ),
          },
          { 
            field: "showInActivitySpecialList", 
            headerName: <FormattedMessage {...messages.showInActivitySpecialListColumn} />,
            flex: 0.5,
            align: "center",
            renderCell: (params) => (
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: params.value ? "green" : "red",
                  borderRadius: 1
                }}
              />
            ),
          },
          {
            field:"edit", 
            headerName:"",
            renderCell:(params) => {
                return(
                    <>
                    <IconButton
                        arial-label="Edit stop reason code"
                        size="small"
                        sx={{
                            "&:hover": {
                            backgroundColor: colors.primary[500],
                            },
                            backgroundColor: colors.primary[700],
                        }}
                        onClick={() => {
                            props.handleEditMachineTypeClick(params.row.id)
                        }}
                        >
                            <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                        arial-label="Delete stop reason code"
                        size="small"
                        sx={{
                            "&:hover": {
                            backgroundColor: colors.primary[500],
                            },
                            backgroundColor: colors.primary[700],
                            marginLeft: "10px"
                        }}
                        onClick={() => {
                          props.handleDeleteMachineTypeClick(params.row.id)
                        }}
                        >
                            <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    </>
                )
            },
            width: 90,
          },
        ]);

      const columnOrderLocalStorageName = 'machine-types-columnOrder';
      const columnWidthsLocalStorageName = 'machine-types-columnWidths';
      
      // Load persisted column order, widths, and sort order on mount
      useEffect(() => {
        let columnsToFix = columns;
        // Load column order
        const savedColumnOrder = localStorage.getItem(columnOrderLocalStorageName);
        if (savedColumnOrder) {
          const orderArray = JSON.parse(savedColumnOrder);
          columnsToFix = reorderColumns(orderArray, columnsToFix);
        }
  
        // Load column widths
        const savedWidths = localStorage.getItem(columnWidthsLocalStorageName);
        if (savedWidths) {
          const widthMap = JSON.parse(savedWidths);
          columnsToFix = applyColumnWidths(widthMap, columnsToFix);
        }
        setColumns(columnsToFix);
      }, []);
  
  
          // Reorder columns based on saved order
      const reorderColumns = (orderArray, columnsToFix) => {
        const reorderedColumns = orderArray.map((field) =>
          columnsToFix.find((col) => col.field === field)
        );
        return reorderedColumns;
      };
  
      // Apply saved column widths
      const applyColumnWidths = (widthMap, columnsToFix) => {
        const updatedColumns = columnsToFix.map((col) => ({
          ...col,
          width: widthMap[col.field] || col.width, 
          flex: widthMap[col.field] !== undefined ? undefined : col.flex, 
        }));
        return updatedColumns;
      };
  
      // Handle column order change
      const handleColumnOrderChange = () => {
        if (!apiRef.current) {
          return;
        }
        const orderedColumns = apiRef.current.getVisibleColumns();
        const newColumnOrder = orderedColumns.map((col) => col.field);
        localStorage.setItem(columnOrderLocalStorageName, JSON.stringify(newColumnOrder));
      };
  
      // Handle column resize
      const handleColumnResize = (params) => {
        const { colDef, width } = params;
        const newWidths = JSON.parse(localStorage.getItem(columnWidthsLocalStorageName)) || {};
        newWidths[colDef.field] = width;
        localStorage.setItem(columnWidthsLocalStorageName, JSON.stringify(newWidths));
      };
        
    if(props.machineTypes?.isLoading) {
        return <AxionControls.AxionBackdrop open={true} />
    }

    return (
        <Box padding={2} paddingTop={8}>
          <Paper
            sx={{
              position: "relative",
              borderRadius: "4px",
            }}
          >
            <Box 
              m="0px 0 0 0"
              height="76vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                },
                "& .name.column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-cellContent": {
                  fontSize: "13px",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.primary[600],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.primary[600],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.gray[100]} !important`,
                },
                "& .super-app-theme--11": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .super-app-theme--undefined-stop": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .MuiDataGrid-columnHeader": {
                  fontSize: "15px",
                },
              }}
            >
              { (!props.machineTypes?.isLoading) && <DataGridPro
                apiRef={apiRef}
                onColumnResize={handleColumnResize}
                onColumnOrderChange={handleColumnOrderChange}
                rows={props.machineTypes?.data}
                sortModel={sortModelStorage}
                onSortModelChange={(newModel) => setSortModelStorage(newModel)}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              /> }
            </Box>
          </Paper>
        </Box>
    );
}